import Ajv from "ajv";
import keywords from "ajv-keywords";
import errors from "ajv-errors";
import mergePatch from "ajv-merge-patch";
import formats from "ajv-formats";
import matcher from 'wildcard-match';
import lodash from 'lodash';

const SCALAR_TYPES = ["number", "integer", "string", "boolean", "null"];

const ajv = new Ajv({ allErrors: true, verbose: true });

mergePatch(formats(errors(keywords(ajv))));

ajv.addKeyword({
  keyword: "maxArrayPropertiesCount",
  type: "object",
  schemaType: "number",
  compile: (maxCount, parentSchema) => {
    const keys = Object.keys(parentSchema.properties).reduce(
      (acc, property) => {
        if (parentSchema.properties[property].type === "array") {
          acc.push(property);
        }
        return acc;
      },
      []
    );
    return (data) => {
      const count = keys.reduce((acc, key) => {
        if (data[key] && Array.isArray(data[key])) {
          acc += data[key].length;
        }
        return acc;
      }, 0);
      return count <= maxCount;
    };
  }
});

function toLowerCaseIfString(s) {
  if (s && typeof s === 'string') {
    return s.toLowerCase();
  }
  return s;
}

// based on https://github.com/ajv-validator/ajv-keywords/blob/master/src/definitions/uniqueItemProperties.ts
ajv.addKeyword({
  keyword: "uniqueItemPropertiesNoCase",
  type: "array",
  schemaType: "array",
  metaSchema: {
    type: "array",
    items: { type: "string" },
  },
  compile: (keys, parentSchema) => {
    const isScalarType = SCALAR_TYPES.includes(parentSchema.items.type);

    return (data) => {
      if (data.length <= 1) {
        return true;
      }

      if (isScalarType) {
        for (let i = data.length; i--;) {
          const x = toLowerCaseIfString(data[i]);
          if (!x) {
            continue;
          }
          for (let j = i; j--;) {
            const y = toLowerCaseIfString(data[j]);
            if (!y) {
              continue;
            }
            if (y === x) {
              return false;
            }
          }
        }
      } else {
        for (let k = 0; k < keys.length; k++) {
          for (let i = data.length; i--;) {
            const x = toLowerCaseIfString(data[i][keys[k]]);
            if (!x) {
              continue;
            }
            for (let j = i; j--;) {
              const y = toLowerCaseIfString(data[j][keys[k]]);
              if (!y) {
                continue;
              }
              if (y === x) {
                return false;
              }
            }
          }
        }
      }
      return true;
    };
  }
});

ajv.addKeyword({
  keyword: "disallowUntitled",
  type: "string",
  schemaType: "boolean",
  compile: (disallow) => {
    return (data) => {
      return data.toString().toLowerCase().includes('untitled') !== disallow;
    };
  }
});

ajv.addKeyword({
  keyword: "maxUniqueItemProperty",
  type: "array",
  schemaType: "object",
  compile: (maxUniqueItems) => {
    return (data) => {
      return Object.entries(maxUniqueItems).find(([propertyName, maxCount]) => {
        const values = data.map(data => data[propertyName]);
        const counts = values.reduce((acc, current) => {
          // we may need to normalize the value
          if (acc[current] === undefined) {
            acc[current] = 1;
          } else {
            acc[current] += 1;
          }
          return acc;
        }, {});
        return !Object.values(counts).find(count => count > maxCount);
      });
    };
  }
});

ajv.addKeyword({
  keyword: "conforming",
  type: ["array", "string"],
  schemaType: "string",
  compile: (conformTo) => {
    return (data) => {
      if (conformTo === 'person-name') {
        if (data.length < 4) {
          // should be at least 4 characters including space ('H Li')
          return false;
        }
        const parts = lodash.words(data);
        if (parts.length < 2) {
          // need first + last name
          return false;
        }
        // should begin with uppercase
        return !parts.find(part => part !== lodash.startCase(part));
      }
      return true;
    };
  }
});

export function installCampaignValidator(campaigns) {
  if (ajv.getKeyword('matchesCampaign')) {
    // remove pre-existing validator
    ajv.removeKeyword('matchesCampaign');
  }
  ajv.addKeyword({
    keyword: "matchesCampaign",
    type: "string",
    schemaType: "string",
    compile: (campaignMatcher) => {
      const isMatch = matcher(campaignMatcher);
      return (data) => {
        const matches = campaigns.filter(isMatch);
        return !!matches.find(campaign => campaign === data);
      };
    }
  });
}

export function installRosterValidator(roster) {
  if (ajv.getKeyword('findInRoster')) {
    // remove pre-existing validator
    ajv.removeKeyword('findInRoster');
  }
  ajv.addKeyword({
    keyword: "findInRoster",
    type: "string",
    schemaType: "string",
    compile: (rosterField) => {
      return (data) => {
        return !!roster.find(entry => entry[rosterField] === data);
      };
    }
  });

  if (ajv.getKeyword('matchesRoster')) {
    // remove pre-existing validator
    ajv.removeKeyword('matchesRoster');
  }
  ajv.addKeyword({
    keyword: "matchesRoster",
    type: "object",
    schemaType: "array",
    metaSchema: {
      // schema to validate keyword value
      type: "array",
      items: { type: "string" },
      minItems: 1
    },
    compile: (rosterFields) => {
      return (data) => {
        const indexField = rosterFields.includes('email') ? 'email' : rosterFields[0];
        const record = roster.find(entry => entry[indexField] === data[indexField]);
        return !!record && rosterFields.reduce((acc, field) => {
          return acc && data[field] === record[field];
        }, true);
      };
    }
  });
}

export function validator(schema) {
  if (Array.isArray(schema) && schema.length > 1) {
    const schemas = [...schema];
    const primary = schemas.pop();
    schemas.forEach((s) => {
      ajv.removeSchema(s.$id);
      ajv.addSchema(s);
    });
    ajv.removeSchema(primary.$id);
    return ajv.compile(primary);
  }
  const s = Array.isArray(schema) ? schema[0] : schema;
  ajv.removeSchema(s.$id);
  return ajv.compile(s);
}

export function getMostImportantErrorFromValidator(validator) {
  const errors = Object.values(validator.errors);
  const errorMessage = errors.find(e => e.keyword === 'errorMessage') || {};
  const required = errors.find(e => e.keyword === 'required') || {};
  const format = errors.find(e => e.keyword === 'format') || {};

  return errorMessage.message || required.message || format.message || errors.shift().message;
}
