import * as actions from '../actions';

const DEFAULT_STATE = {
};

function initializeSchemas(state, schemaDictionary) {
  return schemaDictionary;
}

export default function schemas(state = DEFAULT_STATE, action) {
  switch (action.type) {

    case actions.INIT_SCHEMA_LIST:
      return initializeSchemas(state, action.payload.schemas);

    default:
      break;
  }

  return state;
}
