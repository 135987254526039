import { call, put, takeEvery, takeLatest, all, delay, throttle } from 'redux-saga/effects';
import HttpStatus from 'http-status-codes';

import * as actions from './actions';
import backend from './backend';
import { v4 as uuidv4 } from 'uuid';

import AppController from '../Controllers/AppController';
import { preloadImage } from '../Utils/imageUtils';

const HEALTH_INTERVAL = 30000; // update every 30 seconds

function* navigate(action) {
  yield AppController.navigate(action.payload.location, action.payload.method || AppController.navigate.replace);
}

function* fetchImages() {
  try {
    const images = yield call(backend.fetchImages);
    yield put({
      type: actions.INIT_IMAGE_LIST,
      payload: {
        images
      }
    });
    yield Promise.allSettled(images.map(image => preloadImage(image.url)));
    yield put({
      type: actions.UPDATE_READY_STATE
    });
  } catch (error) {
    AppController.reportError('fetching images', error);
  }
}

function* addImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const image = yield call(backend.addImage, action.payload.image);
    yield put({
      type: actions.ADD_IMAGE,
      payload: {
        image
      }
    });
    yield preloadImage(image.url);
  } catch (error) {
    AppController.reportError('adding image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* removeImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    yield call(backend.removeImage, action.payload.id);
    yield put({
      type: actions.REMOVE_IMAGE,
      payload: {
        id: action.payload.id
      }
    });
  } catch (error) {
    AppController.reportError('removing image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* updateImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const image = yield call(backend.updateImage, action.payload.image);
    yield put({
      type: actions.REPLACE_IMAGE,
      payload: {
        image
      }
    });
  } catch (error) {
    AppController.reportError('updating image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* fetchSchemas() {
  try {
    const schemas = yield call(backend.fetchSchemas);
    yield put({
      type: actions.INIT_SCHEMA_LIST,
      payload: {
        schemas
      }
    });
  } catch (error) {
    AppController.reportError('fetching schemas', error);
  }
}

function* doLogout() {
  yield AppController.logout();
}

function* fetchHealth() {
  try {
    const health = yield call(backend.fetchHealth);
    yield put({
      type: actions.UPDATE_HEALTH,
      payload: {
        ...health,
        connectionStatus: 'connected',
      }
    });
    const user = yield call(backend.fetchUserInfo);
    yield put({
      type: actions.UPDATE_USER,
      payload: {
        user,
      }
    });
  } catch (error) {
    if (error.statusCode === HttpStatus.UNAUTHORIZED) {
      yield AppController.login();
    } else {
      yield put({
        type: actions.UPDATE_HEALTH,
        payload: {
          status: 'down',
          connectionStatus: 'disconnected',
        }
      });
    }
  }
}

function* initServerHealth(action) {
  const interval = (action && action.interval) || HEALTH_INTERVAL;

  while (true) {
    yield put({
      type: actions.FETCH_HEALTH
    });
    yield delay(interval);
  }
}

function* updateAutodata(action) {
  try {
    const autodata = yield call(backend.updateAutodata, action.payload.data);
    yield put({
      type: actions.INIT_AUTODATA,
      payload: {
        autodata
      }
    });
  } catch (error) {
    AppController.reportError('updating autodata store', error);
  }
}

function* addSleeve(action) {
  try {
    const sleeve = yield call(backend.addSleeve, action.payload.title);
    yield put({
      type: actions.ADD_SLEEVE,
      payload: {
        sleeve
      }
    });
  } catch (error) {
    AppController.reportError('adding sleeve', error);
  }
}

function* addBlankSleeveEntries(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();

    let sleeve = action.payload.sleeve;

    sleeve.images = [
      ...(sleeve.images || []),
      ...Array(action.payload.count).fill(null).map(() => (
          {
            id: uuidv4(),
            title: '',
            maker: ''
        }))
    ];

    sleeve = yield call(backend.updateSleeve, sleeve);
    yield put({
      type: actions.REPLACE_SLEEVE,
      payload: {
        sleeve
      }
    });
  } catch (error) {
    AppController.reportError('adding blank sleeve entries', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* updateSleeve(action) {
  try {
    const sleeve = yield call(backend.updateSleeve, action.payload.sleeve);
    yield put({
      type: actions.REPLACE_SLEEVE,
      payload: {
        sleeve
      }
    });
  } catch (error) {
    AppController.reportError('updating sleeve', error);
  }
}


function* synchronizeModelWithRequest(action) {
  const parts = action.payload.location.split('/').filter(part => part.length > 0);
  const tool = parts.shift();

  try {

    if (tool === 'sleeves') {
      const makers = yield call(backend.fetchMakers);
      yield put({
        type: actions.INIT_MAKERS,
        payload: {
          makers
        }
      });

      const sleeves = yield call(backend.fetchSleeves);

      yield put({
        type: actions.INIT_SLEEVES,
        payload: {
          sleeves
        }
      });
    } else {
      const campaigns = yield call(backend.fetchCampaigns);

      yield put({
        type: actions.INIT_CAMPAIGNS,
        payload: {
          campaigns
        }
      });


      const autodata = yield call(backend.fetchAutodata);
      yield put({
        type: actions.INIT_AUTODATA,
        payload: {
          autodata
        }
      });

      yield put({ type: actions.FETCH_IMAGE_LIST });

    }

  } catch (error) {
    if (error.statusCode === HttpStatus.FORBIDDEN && tool === 'sleeves') {
        // just redirect back to catalog
        yield put({
          type: actions.NAVIGATE,
          payload: {
            location: '/catalog',
            method: AppController.navigate.replace
          }
        });
    } else {
      AppController.reportError(tool, error);
    }
  }
}

function* initialize(action) {
  try {
    const config = yield call(backend.fetchConfiguration);
    yield put({
      type: actions.SERVER_CONFIG,
      payload: {
        config
      }
    });
    const schemas = yield call(backend.fetchSchemas);
    yield put({
      type: actions.INIT_SCHEMA_LIST,
      payload: {
        schemas
      }
    });

    yield put({ type: actions.INIT_SERVER_HEALTH });


    // for /catalog we fetch campaigns, autodata, image list


    // for /sleeves we fetch sleeve list and makers
    // ...


    yield AppController.initialized();
  } catch (error) {
    AppController.reportError('initializing datastore', error);
  }
}

function* watchLogout() {
  yield takeLatest(actions.LOGOUT, doLogout);
}

function* watchFetchHealth() {
  yield throttle(HEALTH_INTERVAL, actions.FETCH_HEALTH, fetchHealth);
}

function* watchInitServerHealth() {
  yield takeEvery(actions.INIT_SERVER_HEALTH, initServerHealth);
}

function* watchFetchSchemas() {
  yield takeEvery(actions.FETCH_SCHEMA_LIST, fetchSchemas);
}

function* watchRemoveImage() {
  yield takeEvery(actions.DELETE_IMAGE, removeImage);
}

function* watchUpdateImage() {
  yield takeEvery(actions.UPDATE_IMAGE, updateImage);
}

function* watchUpdateAutodata() {
  yield takeEvery(actions.UPDATE_AUTODATA, updateAutodata);
}

function* watchAddImage() {
  yield takeEvery(actions.ADD_NEW_IMAGE, addImage);
}

function* watchFetchImages() {
  yield takeEvery(actions.FETCH_IMAGE_LIST, fetchImages);
}

function* watchInitialize() {
  yield takeEvery(actions.INITIALIZE, initialize);
}

function* watchNavigate() {
  yield takeEvery(actions.NAVIGATE, navigate);
}

function* watchAddSleeve() {
  yield takeEvery(actions.ADD_NEW_SLEEVE, addSleeve);
}

function* watchUpdateAddBlankSleeveEntries() {
  yield takeEvery(actions.ADD_BLANKS, addBlankSleeveEntries);
}

function* watchUpdateSleeve() {
  yield takeEvery(actions.UPDATE_SLEEVE, updateSleeve);
}


function* watchSynchronize() {
  yield takeEvery(actions.SYNCHRONIZE_DATA_MODEL_WITH_REQUEST, synchronizeModelWithRequest);
}

export default function* rootSaga() {
  yield all([
    watchFetchImages(),
    watchAddImage(),
    watchUpdateImage(),
    watchRemoveImage(),
    watchAddSleeve(),
    watchUpdateAddBlankSleeveEntries(),
    watchUpdateSleeve(),
    watchFetchSchemas(),
    watchFetchHealth(),
    watchUpdateAutodata(),
    watchInitialize(),
    watchInitServerHealth(),
    watchNavigate(),
    watchLogout(),
    watchSynchronize(),
  ]);
}
