import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import {
  DataGrid
} from '@mui/x-data-grid';

import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  Grid,
  IconButton,
  SpeedDialIcon,
  Autocomplete,
  TextField,
} from '@mui/material';


import DialogController from '../../Controllers/DialogController';

import styles from './SleevesView.module.scss';
import * as actions from '../../model/actions';
import { printSleeves } from '../../Utils/ReportUtils';

import Header from '../Header';
import Footer from '../Footer';

class SleevesView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSleeve: 0,
    };
    this.apiRef = undefined;
    this.gridRef = React.createRef();
  }

  handlePrint() {
    const data = this.props.getSleeveByIndex(this.state.selectedSleeve).images;
    printSleeves(
      this.props.sleeves[this.state.selectedSleeve],
      /*  Filter out those which are not empty */
      data.filter((image) => image.title && image.maker),
    );
  }

  handleCellClick(params) {
    if (this.apiRef.state.focus && this.apiRef.state.focus.cell &&
        this.apiRef.state.focus.cell.field === params.field &&
        this.apiRef.state.focus.cell.id === params.id) {
      return;
    }

    this.stopCurrentCellEdit();
    this.apiRef.startCellEditMode({ id: params.id, field: params.field});
  }

  stopCurrentCellEdit() {
    if (this.apiRef.state.focus && this.apiRef.state.focus.cell && this.apiRef.state.focus.cell.field) {
      if (this.apiRef.getCellMode(this.apiRef.state.focus.cell.id, this.apiRef.state.focus.cell.field) === 'edit') {
        this.apiRef.stopCellEditMode({ id: this.apiRef.state.focus.cell.id, field: this.apiRef.state.focus.cell.field });
      }
    }
  }


  handleAdd60() {
    const sleeve = JSON.parse(JSON.stringify(this.props.getSleeveByIndex(this.state.selectedSleeve)));
    this.props.addBlanks(sleeve, 60);
  }

  handleRowUpdate(newSleeveData) {
    const sleeve = JSON.parse(JSON.stringify(this.props.getSleeveByIndex(this.state.selectedSleeve)));
    const index = sleeve.images.findIndex((image) => image.id === newSleeveData.id);
    if (index === -1) {
      throw new Error(`Unable to update sleeve ${newSleeveData.id}`);
    }
    sleeve.images[index] = newSleeveData;
    this.props.updateCurrentSleeve(sleeve);
    return newSleeveData;
  }

  getSelectedSleeveIndex() {
    if (!this.apiRef.state.focus || !this.apiRef.state.focus.cell) {
      return -1;
    }
    return this.props.getSleeveByIndex(this.state.selectedSleeve).images.findIndex(image => image.id === this.apiRef.state.focus.cell.id);
  }

  getSleeveCount() {
   return this.props.getSleeveByIndex(this.state.selectedSleeve).images.length;
  }

  isLastSleeveSelected() {
    return this.getSelectedSleeveIndex() + 1 === this.getSleeveCount();
  }

  forceFocusToGrid() {
//    window.document.activeElement = this.gridRef.current;
    this.gridRef.current.focus();
  }

  handleCellKeyDown(params, event) {
   if ( event.key === 'Tab') {
      this.stopCurrentCellEdit();
      if (params.field === 'title') {
        this.apiRef.setCellFocus({ id: params.id, field: 'maker' });
        this.apiRef.startCellEditMode({ id: params.id, field: 'maker'});
      } else {
        this.apiRef.setCellFocus({ id: params.id, field: 'title' });
        this.apiRef.startCellEditMode({ id: params.id, field: 'title'});
      }
    }
    this.forceFocusToGrid();
  }

  handleAddNewSleeve() {
    // TODO: we just want month/year but we should probably have a date picker
    //    for this and we want to start with the closest previous exhibition Wednesday...
    DialogController.doPrompt('New sleeve name', 'Create New Sleeve', new Intl.DateTimeFormat('en-US', {
      dateStyle: 'long'
    }).format(new Date()))
      .then((response) => {
        this.props.addNewSleeve(response);
      })
      .catch((e) => {});
  }

  renderSleeveList() {
    return (
      <Box className={styles.sleeveList}>
        <List sx={{
                  '& .Mui-selected': {
                     backgroundColor: 'dodgerblue !important',
                  }
            }}>
          {this.props.sleeves.map((sleeve, index) => {
            return (
              <ListItem key={index} selected={index === this.state.selectedSleeve} onClick={() => {
                this.setState({ selectedSleeve: index });
              }}>
                <Typography>{sleeve}</Typography>
              </ListItem>
            );
          })}
        </List>
        <IconButton
          onClick={this.handleAddNewSleeve.bind(this)}
          sx={{ position: 'absolute', bottom: 16, right: 16 , backgroundColor: 'dodgerblue' }}>
          <SpeedDialIcon />
        </IconButton>
      </Box>
    );
  }

  renderSleeveEntries() {

    const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 450,
      type: 'string',
      editable: true,
      valueSetter: (params) => {
        params.row.title = params.value.toUpperCase();
        return params.row;
      },
      renderCell: (params) => {
        this.apiRef = params.api;
        return params.value.toUpperCase();
      }
    },
    {
      field: 'maker',
      headerName: 'Maker',
      width: 350,
      editable: true,
      renderCell: (params) => {
        this.apiRef = params.api;
        return params.value || "";
      },
      renderEditCell: (params) => {
        this.apiRef = params.api;
        return <Autocomplete
          options={this.props.makers}
          fullWidth
          autoHighlight
          value={params.value}
          disablePortal
          onChange={(e, value) => {
            this.apiRef.setEditCellValue({ id: params.id, field: 'maker', value });
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Maker"
              required
              fullWidth
              onChange={(e) => {
                this.apiRef.setEditCellValue({ id: params.id, field: 'maker', value: e.target.value });
              }}
            />
        }
        />;
      },
    }];

    // we could move this to state data
    const data = (this.props.getSleeveByIndex(this.state.selectedSleeve).images) || [];

    return (
        <Box className={styles.dataSheet}>
          <Box sx={{ margin: '1em' }}>
            <DataGrid
              ref={this.gridRef}
              apiRef={this.apiRef}
              rows={data}
              columns={columns}
              autoPageSize
              experimentalFeatures={{ newEditingApi: true }}
              processRowUpdate={this.handleRowUpdate.bind(this)}
              onCellClick={this.handleCellClick.bind(this)}
              onCellKeyDown={this.handleCellKeyDown.bind(this)}
            />
          </Box>
          <Box sx={{ marginLeft: '8px', height: '4em', display: 'flex', alignItems: 'center' }}>
            <Button sx={{ height: '4em' }} onClick={this.handleAdd60.bind(this)}>Add 60 Sleeves</Button>
            <Button sx={{ height: '4em' }} onClick={this.handlePrint.bind(this)}>Print</Button>
          </Box>
        </Box>
    );

  }

  renderSleeves() {
    return (
      <Grid container>
        <Grid item xs={3}>
          {this.renderSleeveList()}
        </Grid>
        <Grid item xs={9}>
          {this.renderSleeveEntries()}
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <Header variant="sleeves" />
            <div className={styles.SleevesView}>
              {this.renderSleeves()}
            </div>
        <Footer variant="sleeves" />
      </div>
    );
  }
}

SleevesView.propTypes = {
  sleeves: PropTypes.arrayOf(PropTypes.string).isRequired,
  makers: PropTypes.array.isRequired,
  getSleeveByIndex: PropTypes.func.isRequired,
  addNewSleeve: PropTypes.func.isRequired,
  updateCurrentSleeve: PropTypes.func.isRequired,
  addBlanks: PropTypes.func.isRequired,
};

SleevesView.defaultProps = {
  getSleeveByIndex: () => { return { images: [] }; },
  addNewSleeve: () => {},
  updateCurrentSleeve: () => {},
  addBlanks: () => {},
  makers: [],
  sleeves: [],
};

SleevesView.connector = (state) => {
  const sleeves = state.sleeves;

  const getSleeveByIndex = (index) => {
    const data = Object.values(state.sleeves);
    if (index < 0 || index >= data.length) {
      return { images: [] };
    }
    return data[index];
  };

  return {
    getSleeveByIndex,
    sleeves: Object.keys(sleeves),
    makers: state.appState.makers,
  };
};

SleevesView.commander = (dispatch) => {
  return {
    addNewSleeve: (title) => dispatch({ type: actions.ADD_NEW_SLEEVE, payload: { title } }),
    addBlanks: (sleeve, count) => dispatch({ type: actions.ADD_BLANKS, payload: { sleeve, count }}),
    updateCurrentSleeve: (sleeve) => dispatch({ type: actions.UPDATE_SLEEVE, payload: { sleeve }}),
  };
};

export default connect(
  SleevesView.connector,
  SleevesView.commander
)(SleevesView);
