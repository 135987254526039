import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import { HotKeys } from 'react-hotkeys';

import ImageDropzone from '../../Components/Dropzone/ImageDropzone';

import { ManagedDialog } from '../../Controllers/DialogController';
import { makeHotKeyHandler } from '../../Utils/MUIUtils';

import styles from './EditImageDialog.module.scss';

import { validator, getMostImportantErrorFromValidator } from '../../Utils/SchemaUtils';

import AppController from '../../Controllers/AppController';
import { validateUpdatingImage } from '../../model/getters';

import Logo from '../../Components/Logo';

export default class EditImageDialog extends ManagedDialog {
  constructor(props) {
    super(props);

    const {
      title,
      category,
    } = props.image;

    this.state = {
      title,
      category,
      validImage: true,
      errorMessage: null,
    };

    // TODO: move this to a property
    this.campaigns = AppController.store.getState().appState.config.campaigns;
    this.campaign = this.campaigns[props.image.campaign];
    if (!this.campaign) {
      throw new Error(`${props.image.campaign} was not found`);
    }

    this.categoryLabel = this.campaign.categoryArchetype ? this.campaign.categoryArchetype : 'Category';

    this.additionalFields = this.campaign.additionalFields && this.campaign.additionalFields['new-image'];

    this.handleSaveImage = this.handleSaveImage.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.handleTitleChanged = this.handleTitleChanged.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.keyMap = {
      save: 'Enter',
      cancel: 'Escape'
    };
    this.handlers = {
      save: this.handleSaveImage,
      cancel: this.handleCancel
    };

    this.hotKeyHandler = makeHotKeyHandler(this.keyMap, this.handlers);
  }

  handleCategoryChanged(e) {
    this.setState({
      category: e.target.value,
    });
  }

  handleTitleChanged(e) {
    this.setState({
      title: e.target.value,
    });
  }

  handleCancel() {
    this.props.onClose();
  }

  handleSaveImage() {
    const {
      id,
      campaign,
    } = this.props.image;
    const {
      title,
      category
    } = this.state;


    const image = {
      id,
      campaign: campaign,
      title,
      category
    };

    const validate = validator(this.props.schema);
    const valid = validate({
      ...this.props.image,
      ...image
    });
    if (!valid) {
      this.setState({
        errorMessage: getMostImportantErrorFromValidator(validate),
      });
      return;
    }

    const collectionErrors = validateUpdatingImage(AppController.store.getState(), {
      ...this.props.image,
      ...image
    });
    if (collectionErrors) {
      this.setState({
        errorMessage: collectionErrors.pop().message,
      });
      return;
    }

    this.props.updateImage(image);
    this.props.onClose();
  }

  makeCategoryDisplayName(category) {
    const inventDisplayName = () => {
      return lodash.startCase(category);
    };
    return this.campaign.categoryDisplayNames ? (this.campaign.categoryDisplayNames[category] || inventDisplayName()) : inventDisplayName();
  }

  renderCategories() {
    return this.props.categories.map(category =>
      <MenuItem key={category} value={category}>{this.makeCategoryDisplayName(category)}</MenuItem>
    );
  }

  renderAdditions() {

    if (this.additionalFields) {

      return this.additionalFields.map((field) => {
        return (<TextField
          disabled
          margin="dense"
          key={field}
          id={field}
          name={field}
          label={lodash.startCase(field)}
          fullWidth
          variant="standard"
          value={this.props.image[field]}
          onKeyDown={this.hotKeyHandler}
        />);
      });
    }
    return null;
  }

  render() {

    let OKButton;
    if (this.state.validImage) {
      OKButton = <Button variant="outlined" onClick={this.handleSaveImage}>Continue</Button>;
    } else {
      OKButton = <Button variant="outlined" disabled>Continue</Button>;
    }

    const additionalFields = this.renderAdditions();

    return (
      <div >
        <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
          <DialogTitle><Logo variant="chip" className={styles.chip} />Edit Image</DialogTitle>
          {this.state.errorMessage && (
            <Alert severity="error" onClose={() => { this.handleCloseAlert(); }}>
              <AlertTitle>Error</AlertTitle>
              {this.state.errorMessage}
            </Alert>
          )}
          <DialogContent>
            <DialogContentText>
              You can only change the Title and/or {this.categoryLabel}. If you need to replace the image you need to
              remove this submission and add a new one.
            </DialogContentText>
            <ImageDropzone onDrop={this.handleFileDropped} value={this.props.image.url} readonly />
            <Box className={styles.form}>
              <TextField
                autoFocus
                required
                margin="dense"
                key="title"
                id="title"
                name="title"
                label="Title"
                fullWidth
                variant="standard"
                value={this.state.title}
                onChange={this.handleTitleChanged}
                onKeyDown={this.hotKeyHandler}
              />
              <Box className={styles.categoryGrid}>
                <InputLabel
                  className={styles.categoryLabel}
                  id="category-select-label">
                  {this.categoryLabel}
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  required
                  key="category"
                  variant="standard"
                  value={this.state.category}
                  onChange={this.handleCategoryChanged}
                  label="Age"
                  onKeyDown={this.hotKeyHandler}
                >
                  {this.renderCategories()}
                </Select>
              </Box>
              {additionalFields}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={this.handleCancel}>Cancel</Button>
            {OKButton}
          </DialogActions>
        </HotKeys>
      </div>
    );
  }
}

EditImageDialog.propTypes = {
  updateImage: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired
};
