import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Logo from '../../Components/Logo';
import styles from './Header.module.scss';

function possessiveForm(noun) {
  if (noun.endsWith('s')) {
    return `${noun}'`;
  }
  return `${noun}'s`;
}

class Header extends Component {

  render() {
    const styleObj = {
      //      marginRight: '20px',
      borderRadius: this.props.club ? 0 : undefined,
    };
    return (
      <AppBar position="static">
        <Toolbar variant="dense" style={{ backgroundColor: 'black' }}>
          <Avatar alt={this.props.userName} src={this.props.avatar} className={styles.avatar} sx={styleObj} />
          <Typography variant="h6" color="inherit" component="div">
            {this.props.variant === 'sleeves' ? this.props.sleevesTitle : this.props.submissionsTitle}
          </Typography>
          <a className={styles.logo} href="https://darkroomers.com" target="_blank" rel="noreferrer" >
            <Logo variant="dark" className={styles.logoIcon} />
            <Logo variant="chip" className={styles.chipIcon} />
          </a>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  submissionsTitle: PropTypes.string,
  sleevesTitle: PropTypes.string,
  avatar: PropTypes.string,
  userName: PropTypes.string,
  club: PropTypes.string,
  clubName: PropTypes.string,
  variant: PropTypes.oneOf(['catalog', 'sleeves']),
};

Header.defaultProps = {
  submissionsTitle: '',
  sleevesTitle: '',
  avatar: '',
  userName: '',
  club: null,
  clubName: null,
  variant: 'catalog',
};

Header.connector = (state) => {
  const submissionsTitle = state.appState.user ? `${possessiveForm(state.appState.user.firstName)} Submissions` : 'Submissions';
  const userName = state.appState.user ? state.appState.user.userName : 'Not logged in';
  const avatar = state.appState.user ? state.appState.user.avatar : '/';
  const rep = state.appState.user && state.appState.user.scopes.split(';').find(scope => scope.startsWith('rep='));
  const club = rep ? rep.split('=').pop() : null;
  // TODO: change club= to affiliate= in the scopes
  const affiliation = state.appState.user && state.appState.user.scopes.split(';').find(scope => scope.startsWith('club='));
  const clubName = affiliation ? affiliation.split('=').pop() : null;
  const sleevesTitle = clubName ? `${clubName} Sleeves` : 'Sleeves';

  return {
    submissionsTitle,
    sleevesTitle,
    userName,
    avatar,
    club,
    clubName
  };
};

Header.commander = (dispatch) => {
  return {
  };
};

export default connect(
  Header.connector,
  Header.commander
)(Header);
