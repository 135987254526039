import { createSelector } from 'reselect';
import lodash from 'lodash';

import { validator } from '../Utils/SchemaUtils';

import { getImageById, filterImagesByTitleAndCampaign } from './reducers/images';

function flattenImages(images) {
  return Object.values(images);
}

export function getFlattenedImages(state) {
  return flattenImages(state.images.toJS());
}

export function hasImageWithTitleForCampaign(state, title, campaign, excludeIds = []) {
  const images = filterImagesByTitleAndCampaign(state.images, title, campaign);
  return images.filter(image => !excludeIds.includes(image.id)).length > 0;
}

export function getImage(state, id) {
  return getImageById(state.images, id);
}

export function transformFlattenedImageListIntoCategoriesByCampaign(campaign, images) {
  const starter = campaign.categories.reduce((acc, category) => {
    acc[category] = [];
    return acc;
  }, {});
  return images.reduce((acc, image) => {
    acc[image.category].push(image);
    return acc;
  }, starter);
}

export function getImagesByCategory(state, campaign) {
  const images = Object
    .values(getFlattenedImages(state))
    .filter(obj => obj.campaign === campaign.id);

  return transformFlattenedImageListIntoCategoriesByCampaign(campaign, images);
}

export function getSchema(state, schema) {
  if (Array.isArray(schema)) {
    return schema.map(s => state.schemas[s]);
  }
  return state.schemas[schema];
}

export function testAddingMoreImages(state) {
  const campaign = state.appState.config.campaigns[state.appState.config.currentCampaign];
  const collectionSchema = getSchema(state, campaign.schemas.collection);
  const collectionByCategory = getImagesByCategory(state, campaign);
  const validate = validator(collectionSchema);
  const valid = validate(collectionByCategory);
  if (!valid) {
    return {
      result: false,
      message: validate.errors.pop().message,
    };
  }

  // find the least filled category and add 1
  let entries = Object.entries(collectionByCategory).reduce((acc, [key, value]) => {
    acc.push({
      key,
      length: value.length
    });
    return acc;
  }, []);

  entries = lodash.sortBy(entries, ['length']);

  const entry = entries.shift();
  collectionByCategory[entry.key].push({
  });

  const stillValid = validate(collectionByCategory);
  if (!stillValid) {
    return {
      result: false,
      message: validate.errors.pop().message,
    };
  }

  return {
    result: true,
  };
}

export function testAddingMoreImagesToCategory(state, category) {
  const campaign = state.appState.config.campaigns[state.appState.config.currentCampaign];
  const collectionSchema = getSchema(state, campaign.schemas.collection);
  const collectionByCategory = getImagesByCategory(state, campaign);

  collectionByCategory[category].push({});

  const validate = validator(collectionSchema);
  const valid = validate(collectionByCategory);
  if (!valid) {
    return {
      result: false,
      message: validate.errors.pop().message,
    };
  }
  return {
    result: true,
  };
}

export function validateAddingNewImage(state, image) {
  const campaign = state.appState.config.campaigns[state.appState.config.currentCampaign];
  const collectionSchema = state.schemas[campaign.schemas.collection];
  const collectionByCategory = getImagesByCategory(state, campaign);

  collectionByCategory[image.category].push(image);

  const validate = validator(collectionSchema);
  const valid = validate(collectionByCategory);
  if (!valid) {
    return validate.errors;
  }
  return null;
}

export function validateUpdatingImage(state, image) {
  const campaign = state.appState.config.campaigns[state.appState.config.currentCampaign];
  const collectionSchema = state.schemas[campaign.schemas.collection];

  const images = Object
    .values(getFlattenedImages(state))
    .filter(obj => obj.campaign === campaign.id && obj.id !== image.id);

  const collectionByCategory = transformFlattenedImageListIntoCategoriesByCampaign(campaign, images);

  collectionByCategory[image.category].push(image);

  const validate = validator(collectionSchema);
  const valid = validate(collectionByCategory);
  if (!valid) {
    return validate.errors;
  }
  return null;
}

export function getCampaignNameById(state, id) {
  return Object.values(state.appState.config.campaigns).reduce((acc, campaign) => {
    if (campaign.id === id) {
      acc = campaign.name;
    }
    return acc;
  }, '');
}

// move to utils
function hasMatch(s, text) {
  const values = Array.isArray(s) ? s : lodash.values(s);
  return lodash.find(values, (content) => {
    if (lodash.isPlainObject(content) || Array.isArray(content)) {
      return hasMatch(content, text);
    }
    // force numbers to strings
    return content && content.toString().toLowerCase().includes(text);
  });
}

export const makeFilteredImagesGetter = () => {
  return createSelector(
    [(state) => state.images.toJS(), (state) => state.appState], (images, appState) => {
      const filter = appState.filter;
      const campaign = appState.config.currentCampaign;
      let result = flattenImages(images);
      if (campaign !== '*') {
        result = lodash.pickBy(result, resource => resource.campaign === campaign);
      }
      if (filter.category !== '*') {
        result = lodash.pickBy(result, resource => resource.category === filter.category);
      }
      if (filter.content.trim() !== '') {
        const content = filter.content.toLowerCase().trim();
        result = lodash.pickBy(result, (resource) => {
          return hasMatch(resource, content);
        });
      }
      return result;
    }
  );
};

export function testCurrentFilterWillShowImage(state, image) {
  const filter = state.appState.filter;
  let match = true;
  if (filter.category !== '*') {
    match = match && image.category === filter.category;
  }
  if (filter.content.trim() !== '') {
    const content = filter.content.toLowerCase().trim();
    match = match && hasMatch(image, content);
  }
  return match;
}
