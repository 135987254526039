import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
// import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import AddNewImageDialog from '../../Dialogs/AddNewImageDialog';
import EditImageDialog from '../../Dialogs/EditImageDialog';
import DialogController from '../../Controllers/DialogController';
import * as actions from '../../model/actions';
import { getImage, getSchema, makeFilteredImagesGetter, testAddingMoreImages, testAddingMoreImagesToCategory, testCurrentFilterWillShowImage } from '../../model/getters';
import { isTodayWithinDateRange, isNearingDate, isBeforeDate, isAfterDate } from '../../Utils/DateUtils';

import styles from './ImageCatalogView.module.scss';
import AppController from '../../Controllers/AppController';

import Header from '../Header';
import Footer from '../Footer';

const deviceClasses = {
  height: '100%',
  width: '100%',
  maxWidth: 'unset !important',
  maxHeight: 'unset !important',
  margin: '0 !important',
};

const dialogClasses = {
  '& .MuiDialog-paper': {
    '@media(max-width: 680px)': {
      ...deviceClasses,
    },
    '@media(max-height: 900px)': {
      ...deviceClasses,
    },
  }
};

class ImageCatalogView extends Component {
  constructor(props) {
    super(props);

    this.handleAddImage = this.handleAddImage.bind(this);
    this.renderImageCard = this.renderImageCard.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleAddNewImage = this.handleAddNewImage.bind(this);

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info'
    };
  }

  renderAddButton() {
    return (
      <Button variant="outlined" onClick={this.handleAddImage}>
        <AddToPhotosIcon fontSize="large" />
      </Button>
    );
  }

  renderAddNewCard() {
    return (
      <Grid style={{
        maxWidth: '345px',
        minWidth: '345px',
      }}
        item
        key={'add'}
        xs={6}
      >
        <Card sx={{
          maxWidth: 345,
          minWidth: 345,
          maxHeight: 310,
          minHeight: 310,
          backgroundColor: 'black',
          color: 'white',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#1976d2',
            '*': {
              color: 'white',
              borderColor: 'white',
            },
          }
        }} onClick={this.handleAddImage}>
          <CardContent sx={{
            height: '260px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            {this.renderAddButton()}
            <Typography sx={{
              marginLeft: '20px',
            }}
              variant="h6"
              color="inherit"
              component="div"
            >
              Add New Image
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  renderImageCard(image, index) {
    return (
      <Grid style={{
        maxWidth: '345px',
        minWidth: '345px',
      }}
        item
        key={`image-${index}`}
        xs={6}
      >
        <Card sx={{
          maxWidth: 345,
          minWidth: 345,
          maxHeight: 310,
          minHeight: 310,
          cursor: 'pointer',
          position: 'relative',
          '&:hover': {
            backgroundColor: '#1976d2',
            '*': {
              color: 'white',
              borderColor: 'white',
            },
          }
        }} onClick={(e) => this.handleEditImage(e, image.id)}>
          <CardMedia
            component="img"
            alt={image.title}
            height="200"
            image={image.url}
          />
          <div className={styles.cardContent}>
            <Typography variant="body1" color="text.primary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {image.title}
            </Typography>
            {image.maker && (
              <Typography variant="subtitle2" color="text.primary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                by {image.maker}
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              {this.props.campaign.categoryArchetype} {
                this.props.campaign.categoryDisplayNames
                  ? this.props.campaign.categoryDisplayNames[image.category]
                  : image.category
              }
            </Typography>
          </div>
          <div className={styles.cardButtons}>
            <IconButton
              size="small"
              color="primary"
              aria-label="delete"
              title="delete"
              component="span"
              onClick={(e) => this.handleRemoveImage(e, image.id)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              aria-label="edit"
              title="edit"
              component="span"
              onClick={(e) => this.handleEditImage(e, image.id)}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Card>
      </Grid>
    );
  }

  renderImageGrid() {
    const cards = [
      this.renderAddNewCard(),
      ...this.filteredImages.map(this.renderImageCard),
    ];
    return (
      <Box className={styles.imageGrid}>
        <Grid sx={{
          marginLeft: '10px',
          marginTop: 0,
          width: 'calc(100% - 16px)',
          paddingBottom: '100px',
          columnGap: '30px',
          '@media(max-width: 786px)': {
            flexDirection: 'column',
          },
          '& .MuiGrid-item': {
            '@media(max-width: 786px)': {
              paddingLeft: 0,
              paddingRight: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          }
        }}
          container
          rowSpacing={4}
          columnSpacing={8}
        >
          {cards}
        </Grid>
      </Box>
    );
  }

  renderLonelyContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        {this.renderAddButton()}
        <Typography variant="h6" color="inherit" component="div">
          It's pretty lonely in here. Try adding some images...
        </Typography>
      </div>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Move along... Nothing to see here...
        </Typography>
      </div>
    );
  }

  handleAddNewImage(image) {
    if (!this.props.testCurrentFilterWillShowImage(image)) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: 'Resetting filters to show newly added image',
        snackbarSeverity: 'warning',
      });
      this.props.resetAllFilters();
    }
    this.props.addNewImage(image);
  }


  handleAddImage(e) {
    e.preventDefault();
    e.stopPropagation();
    const test = this.props.testAddingMoreImages();
    if (test.result !== true) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: test.message,
        snackbarSeverity: 'error',
      });
    } else if (!this.props.isCurrentCampaignOpenForEntries()) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: `${this.props.campaign.name} is closed for entries`,
        snackbarSeverity: 'error',
      });
    } else {
      DialogController.doModal(<AddNewImageDialog
        addNewImage={this.handleAddNewImage}
        campaign={this.props.campaign.id}
        schema={this.props.getSchema(this.props.campaign.schemas['new-image'])}
        categories={this.props.getAvailableCategories()}
      />, { sx: dialogClasses });
    }
  }

  handleEditImage(e, id) {
    e.preventDefault();
    e.stopPropagation();
    const image = this.props.getImage(id);
    DialogController.doModal(<EditImageDialog
      updateImage={this.props.updateImage}
      image={image}
      schema={this.props.getSchema(this.props.campaign.schemas['update-image'])}
      categories={this.props.computeAvailableCategoriesForImage(image)}
    />, { sx: dialogClasses });
  }

  handleRemoveImage(e, id) {
    e.preventDefault();
    e.stopPropagation();
    DialogController.doAlert('Are you sure you want to delete this entry?  This action is not undoable...', 'Delete Entry', 'yesNo')
      .then((response) => {
        if (response === 'yes') {
          this.props.removeImage(id, this.props.campaign);
        }
      })
      .catch((error) => {
        AppController.doAlert(error.message, 'Delete Image');
      });
  }

  handleCloseSnackbar() {
    this.setState({ openSnackbar: false });
  }

  get filteredImages() {
    return this.props.getFilteredImages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.campaign.id !== prevProps.campaign.id) {
      if (isNearingDate(this.props.campaign.endDate, '1 week')) {
        this.setState(() => {
          return {
            openSnackbar: true,
            snackbarMessage: 'Campaign closing date approaching. Finish your entries soon.',
            snackbarSeverity: 'info',
          };
        });
      } else if (isAfterDate(this.props.campaign.endDate)) {
        this.setState(() => {
          return {
            openSnackbar: true,
            snackbarMessage: 'This campaign is no longer accepting entries.',
            snackbarSeverity: 'warning',
          };
        });
      } else if (isBeforeDate(this.props.campaign.startDate)) {
        this.setState(() => {
          return {
            openSnackbar: true,
            snackbarMessage: 'This campaign is not yet accepting entries.',
            snackbarSeverity: 'warning',
          };
        });
      }
    }
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={styles.loader}>
          <RingLoader className={styles.loader} color="white" />
        </div>
      );
    } else if (this.props.images.length === 0) {
      content = this.renderLonelyContent();
    } else if (this.filteredImages.length === 0 && this.props.filter !== '') {
      content = this.renderNoResultsContent();
    } else {
      content = this.renderImageGrid();
    }

    return (
      <div>
        <Header />
            <div className={styles.imageCatalogView}>
              <Snackbar
                open={this.state.openSnackbar}
                autoHideDuration={6000}
                onClose={this.handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
                  {this.state.snackbarMessage}
                </Alert>
              </Snackbar>
              {content}
            </div>
          <Footer />
      </div>
    );
  }
}

ImageCatalogView.propTypes = {
  filter: PropTypes.string,
  images: PropTypes.array,
  initializing: PropTypes.bool.isRequired,
  campaign: PropTypes.object.isRequired,
  addNewImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  getImage: PropTypes.func.isRequired,
  getSchema: PropTypes.func.isRequired,
  computeAvailableCategoriesForImage: PropTypes.func.isRequired,
  isCurrentCampaignOpenForEntries: PropTypes.func.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  testCurrentFilterWillShowImage: PropTypes.func.isRequired,
};

ImageCatalogView.defaultProps = {
  filter: '',
  images: [],
  getFilteredImages: () => [],
  getAvailableCategories: () => { },
  testAddingMoreImages: () => { },
  computeAvailableCategoriesForImage: () => { },
  getImage: () => { },
  campaign: {},
  initializing: true,
  getSchema: () => { },
  isCurrentCampaignOpenForEntries: () => { },
};

ImageCatalogView.connector = (state) => {
  const getFilteredImages = makeFilteredImagesGetter();

  const currentCampaign = state.appState.config.currentCampaign;
  const campaign = state.appState.config.campaigns[currentCampaign];
  const initializing = !state.appState.ready;

  return {
    getImage: (id) => getImage(state, id),
    filter: state.appState.filter.content,
    images: Object.values(state.images.toJS()),
    campaign,
    initializing,
    getSchema: (schema) => {
      return getSchema(state, schema);
    },
    getFilteredImages: () => {
      return Object.values(getFilteredImages(state));
    },
    testAddingMoreImages: () => {
      return testAddingMoreImages(state);
    },
    getAvailableCategories: () => {
      return campaign.categories.filter((category) => {
        return testAddingMoreImagesToCategory(state, category).result === true;
      });
    },
    computeAvailableCategoriesForImage: (image) => {
      return campaign.categories.filter((category) => {
        return testAddingMoreImagesToCategory(state, category).result === true || image.category === category;
      });
    },
    isCurrentCampaignOpenForEntries: () => {
      return isTodayWithinDateRange(campaign.startDate, campaign.endDate);
    },
    testCurrentFilterWillShowImage: (image) => {
      return testCurrentFilterWillShowImage(state, image);
    }
  };
};

ImageCatalogView.commander = (dispatch) => {
  return {
    addNewImage: (image) => dispatch({ type: actions.ADD_NEW_IMAGE, payload: { image } }),
    removeImage: (id) => dispatch({ type: actions.DELETE_IMAGE, payload: { id } }),
    updateImage: (image) => dispatch({ type: actions.UPDATE_IMAGE, payload: { image } }),
    resetAllFilters: () => dispatch({ type: actions.RESET_FILTERS }),
  };
};


export default connect(
  ImageCatalogView.connector,
  ImageCatalogView.commander
)(ImageCatalogView);
