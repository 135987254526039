// import lodash from 'lodash';
// import Immutable from 'immutable';
// import * as actions from '../actions';

import * as actions from '../actions';

const BASE_STATE = {
};

function initializeSleeves(state, sleeves) {
  // not sure what the data will look like yet...
  return {
    ...state,
    ...sleeves.reduce((acc, sleeve) => {
      acc[sleeve.title] = sleeve;
      return acc;
    }, {})
  };
}

function replaceSleeve(state, sleeve) {
  const title = sleeve.title;
  if (!state[title]) {
    throw new Error(`Sleeve ${title} not found`);
  }
  return { ...state, [title]: sleeve };
}

function addSleeve(state, sleeve) {
  const title = sleeve.title;
  if (state[title]) {
    throw new Error(`Sleeve ${title} already exists`);
  }
  return { ...state, [title]: sleeve };
}

export default function sleeves(state = BASE_STATE, action) {
  switch (action.type) {
    case actions.INIT_SLEEVES:
      return initializeSleeves(state, action.payload.sleeves);
    case actions.REPLACE_SLEEVE:
      return replaceSleeve(state, action.payload.sleeve);
    case actions.ADD_SLEEVE:
      return addSleeve(state, action.payload.sleeve);
    default:
      break;
  }
  return state;
}
