import React, { Component } from 'react';
import PropTypes from 'prop-types';
import matcher from 'wildcard-match';

import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AppController from '../../Controllers/AppController';

import * as actions from '../../model/actions';

import {
  getCampaignNameById,
  getFlattenedImages,
} from '../../model/getters';

import styles from './Footer.module.scss';
import { Button, Typography } from '@mui/material';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.searchRef = React.createRef();

    this.handleSearchClicked = this.handleSearchClicked.bind(this);
    this.handleRules = this.handleRules.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleCampaignChanged = this.handleCampaignChanged.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);
    this.handleCampaignMenuOpen = this.handleCampaignMenuOpen.bind(this);
    this.handleCatalog = this.handleCatalog.bind(this);
    this.handleSleeves = this.handleSleeves.bind(this);

    this.state = {
      menuAnchor: null,
      menuOpen: false,
      campaignAnchor: null,
      campaignOpen: false,
    };
  }

  handleSearchClicked() {
    this.searchRef.current.focus();
  }

  handleRules() {
    this.setMenuState(false);
    AppController.openURL('https://darkroomers.com/bylaws#rules');
  }

  handleCloseMenu() {
    this.setMenuState(false);
  }

  handleLogout() {
    this.setMenuState(false);
    this.props.logout();
  }

  handleOpenMenu(e) {
    this.setMenuState(e);
  }

  handleSwitchToCampaign(id) {
    this.setMenuState(null);
    this.props.switchToCampaign(id);
  }

  handleCampaignMenuOpen(e) {
    this.setCampaignMenuState(e);
  }

  handleCampaignChanged(e) {
    this.setState({
      menuOpen: false,
    });
    this.props.switchToCampaign(e.target.value);
  }

  handleCategoryChanged(e) {
    this.setState({
      menuOpen: false,
    });
    this.props.filterByCategory(e.target.value);
  }

  handleSearchChanged(e) {
    this.props.filterByContent(e.currentTarget.value);
  }

  handleSleeves() {
    this.props.switchToSleevesView();
  }

  handleCatalog() {
    this.props.switchToCatalogView();

  }

  setMenuState(e) {
    if (!e) {
      this.setState({
        menuOpen: false,
        campaignOpen: false,
      });
    } else {
      this.setState({
        menuAnchor: e.currentTarget,
        menuOpen: true,
        campaignOpen: false,
      });
    }
  }

  setCampaignMenuState(e) {
    if (!e) {
      this.setState({
        menuOpen: false,
        campaignOpen: false,
      });
    } else {
      this.setState({
        campaignAnchor: e.currentTarget,
        menuOpen: false,
        campaignOpen: true,
      });
    }
  }

  renderCatalogVariant() {

    const campaigns = this.props.campaigns.map((id) => {
      return (<MenuItem
        key={id}
        value={id}
        onClick={() => this.handleSwitchToCampaign(id)}
      >
        {this.props.getCampaignNameById(id)}
      </MenuItem>);
    });

    const categories = this.props.categories.map((category) => {
      return (<MenuItem key={category} value={category}>{category}</MenuItem>);
    });
    categories.push(<MenuItem key="*" value="*">All</MenuItem>);

    if (this.searchRef.current && this.searchRef.current.value !== this.props.filter) {
      // WORKAROUND: The controlled text field doesn't get reset from an external change
      //              so if we reset this due to a command then we need to handle it directly
      this.searchRef.current.value = this.props.filter;
    }

    return (
      <div className={styles.text}>
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar style={{ backgroundColor: 'black' }}>
            <Button
              className={styles.campaignButton}
              onClick={this.handleCampaignMenuOpen}
            >
              {this.props.getCampaignNameById(this.props.campaign)}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={this.state.campaignAnchor}
              open={this.state.campaignOpen}
              onClose={this.handleCloseMenu}
            >
              {campaigns}
            </Menu>
            <Typography variant='h5' className={styles.totalImages}>
              {this.props.totalImages === 0 ? 'No' : this.props.totalImages} Images
            </Typography>
            <IconButton color="inherit" sx={{ marginTop: '8px', position: 'absolute', right: 0 }} onClick={this.handleOpenMenu}>
              <MoreIcon fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
          id="footer-menu"
          anchorEl={this.state.menuAnchor}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <Box
            sx={{ padding: "1em", }}
          >
            <Select
              label="Campaign"
              variant="standard"
              value={this.props.campaign}
              onChange={this.handleCampaignChanged}
              sx={{
                width: "100%",
              }}
            >
              {campaigns}
            </Select>
            <Select
              label="Category"
              sx={{
                width: "100%",
                marginTop: "1.2em",
                marginBottom: "1em",
              }}
              variant="standard"
              value={this.props.category}
              onChange={this.handleCategoryChanged}
            >
              {categories}
            </Select>
            <TextField
              color="primary"
              label="Search"
              inputRef={this.searchRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon onClick={this.handleSearchClicked} />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              onChange={this.handleSearchChanged}
              val={this.props.filter}
              sx={{
                width: "100%",
                marginTop: "1.2em",
              }}
            />
            <MenuItem divider />
            {this.props.hasSleevesAccess && <MenuItem onClick={this.handleSleeves}>Sleeves</MenuItem>}
            <MenuItem onClick={this.handleRules}>Rules</MenuItem>
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
          </Box>
        </Menu>
      </div>
    );
  }

  renderSleevesVariant() {

    return (
      <div className={styles.text}>
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar style={{ backgroundColor: 'black' }}>
            <IconButton color="inherit" sx={{ marginTop: '8px', position: 'absolute', right: 0 }} onClick={this.handleOpenMenu}>
              <MoreIcon fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
          id="footer-menu"
          anchorEl={this.state.menuAnchor}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
        >
          <Box
            sx={{ padding: "1em", }}
          >
            <MenuItem onClick={this.handleCatalog}>Catalog</MenuItem>
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
          </Box>
        </Menu>
      </div>
    );
  }

  render() {
    if (this.props.variant === 'catalog') {
      return this.renderCatalogVariant();
    }
    return this.renderSleevesVariant();
  }
}



Footer.propTypes = {
  filter: PropTypes.string,
  campaign: PropTypes.string,
  campaigns: PropTypes.arrayOf(PropTypes.string),
  getCampaignNameById: PropTypes.func,
  filterByContent: PropTypes.func,
  switchToCampaign: PropTypes.func,
  logout: PropTypes.func,
  totalImages: PropTypes.number,
  variant: PropTypes.oneOf(['catalog', 'sleeves']),
  hasSleevesAccess: PropTypes.bool,
};

Footer.defaultProps = {
  filter: '',
  campaign: '',
  category: '*',
  campaigns: [],
  categories: [],
  getCampaignNameById: () => { },
  filterByContent: () => { },
  switchToCampaign: () => { },
  logout: () => { },
  totalImages: 0,
  variant: 'catalog',
  hasSleevesAccess: false,
};

export function profileHasScope(profile, scope) {
  const allowed = profile.scopes ? (profile.scopes.split(';').find((value) => value.startsWith('allowed=')) || '') : '';
  const [, content] = allowed.split('=');
  const features = content ? (content.split(',').map(feature => feature.trim())) : [];
  return features.some(feature => matcher(feature, { flags: 'i' })(scope));
}

Footer.connector = (state) => {
  // we'll just test basic look-see access
  const hasSleevesAccess = profileHasScope(state.appState.user, 'thunder.sleeves.list');

  const campaign = state.appState.config.campaigns[state.appState.config.currentCampaign].id;
  const totalImages = getFlattenedImages(state).filter(image => image.campaign === campaign).length;
  return {
    filter: state.appState.filter.content,
    category: state.appState.filter.category,
    categories: state.appState.config.campaigns[state.appState.config.currentCampaign].categories,
    campaign,
    campaigns: Object.keys(state.appState.config.campaigns),
    getCampaignNameById: (id) => getCampaignNameById(state, id),
    totalImages,
    hasSleevesAccess,
  };
};

Footer.commander = (dispatch) => {
  return {
    filterByContent: (content) => dispatch({ type: actions.FILTER_IMAGES, payload: { content } }),
    filterByCategory: (category) => dispatch({ type: actions.FILTER_IMAGES, payload: { category } }),
    switchToCampaign: (campaign) => dispatch({ type: actions.SET_ACTIVE_CAMPAIGN, payload: { campaign } }),
    logout: () => dispatch({ type: actions.LOGOUT }),
    switchToSleevesView: () => dispatch({ type: actions.NAVIGATE, payload: { location: '/sleeves' }}),
    switchToCatalogView: () => dispatch({ type: actions.NAVIGATE, payload: { location: '/catalog' }}),
  };
};


export default connect(
  Footer.connector,
  Footer.commander
)(Footer);
