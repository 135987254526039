import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Dropzone from './Dropzone';
import styles from './Dropzone.module.scss';

export default class ImageDropzone extends Dropzone {

  constructor(props) {
    super(props);

    this.state = {
      url: this.props.value
    };

    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    if (files.length < 1) {
      console.log('Received a drop without any files');
      return;
    }

    this.props.onDrop(files);

    const file = files[0];
    const url = URL.createObjectURL(file);

    this.setState({
      url,
    });
  }


  renderFileContents() {
    return (
      <div className={styles.fileContent} style={{ backgroundImage: `url(${this.state.url})` }} />
    );
  }

  renderContent() {
    if (!this.state.url) {
      return this.renderDropHereContent();
    }
    return this.renderFileContents();
  }

  render() {
    if (this.props.readonly && this.state.url !== null) {
      return (
        <div className={clsx(styles.dropZone, styles.readonly)}>
          {this.renderContent()}
        </div>
      );
    }
    return super.render();
  }

}

ImageDropzone.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  readonly: PropTypes.bool,
  url: PropTypes.string,
};

ImageDropzone.defaultProps = {
  onDrop: () => { },
  accept: 'image/jpeg',
  readonly: false,
  url: null,
};
