import React from 'react';
import PropTypes from 'prop-types';

import lodash from 'lodash';

import ExifReader from 'exifreader';

import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import { HotKeys } from 'react-hotkeys';
import { preloadImage } from '../../Utils/imageUtils';
import ImageDropzone from '../../Components/Dropzone/ImageDropzone';

import AppController from '../../Controllers/AppController';
import { validateAddingNewImage } from '../../model/getters';
import { makeHotKeyHandler } from '../../Utils/MUIUtils';
import { isDateWithinDateRange } from '../../Utils/DateUtils';
import { ManagedDialog } from '../../Controllers/DialogController';

import backend from '../../model/backend';

import Logo from '../../Components/Logo';

import styles from './AddNewImageDialog.module.scss';

import { validator, getMostImportantErrorFromValidator } from '../../Utils/SchemaUtils';


const maxImageFileSize = 14000000;

export default class AddNewImageDialog extends ManagedDialog {
  constructor(props) {
    super(props);

    this.state = {
      category: lodash.get(props, 'categories[0]', 'monochrome'),
      title: '',
      file: null,
      errorMessage: null,
      validImage: false,
      autocomplete: 'closed',
    };

    // TODO: move this to a property
    this.campaigns = AppController.store.getState().appState.config.campaigns;
    this.campaign = this.campaigns[props.campaign];
    if (!this.campaign) {
      throw new Error(`${props.campaign} was not found`);
    }
    this.autodata = AppController.store.getState().appState.autodata;
    this.categoryLabel = this.campaign.categoryArchetype ? this.campaign.categoryArchetype : 'Category';

    this.additionalFields = this.campaign.additionalFields && this.campaign.additionalFields['new-image'];
    if (this.additionalFields) {
      this.additionalFields.forEach(field => this.state[field] = '');
    }

    this.handleAddNewImage = this.handleAddNewImage.bind(this);
    this.handleFileDropped = this.handleFileDropped.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.handleTitleChanged = this.handleTitleChanged.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.keyMap = {
      save: 'Enter',
      cancel: 'Escape'
    };
    this.handlers = {
      save: this.handleAddNewImage,
      cancel: this.handleCancel
    };

    this.hotKeyHandler = makeHotKeyHandler(this.keyMap, this.handlers);
  }

  handleCloseAlert() {
    this.setState({
      errorMessage: null,
    });
  }

  handleFileDropped(files) {
    this.setState({
      file: files[0],
    });
    this.checkImageUpload();
  }

  handleCategoryChanged(e) {
    this.setState({
      category: e.target.value,
    });
  }

  handleTitleChanged(e) {
    this.setState({
      title: e.target.value,
    });
  }

  handleCancel() {
    this.props.onClose();
  }

  handleDropzoneKey(e) {
    if (e.keycode === 'enter') {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  async handleAddNewImage(e) {
    const image = {
      campaign: this.props.campaign,
      title: this.state.title,
      category: this.state.category,
      file: this.state.file,
    };

    if (this.state.autocomplete === 'open') {
      // Don't let the enter key dismiss the dialog until
      //  something is chosen from the autocomplete list...
      return;
    }

    if (!image.file) {
      this.setState({
        errorMessage: "Image File is Required"
      });
      return;
    }

    if (this.additionalFields) {
      // additional fields are required...
      const missing = this.additionalFields.find(field => !this.state[field]);
      if (missing) {
        this.setState({
          errorMessage: `${lodash.startCase(missing)} is Required`
        });
        return;
      }
    }


    AppController.enterPotentiallyLengthyOperation();

    /**
     * check to see if we need to update anything on the roster
     * TODO: Let this be a configuration setting on the part of the campaign
     */
    const autodataUpdates = {
    };

    if (this.additionalFields) {

      /**
       * build a list of anything that is updated
       */
      let updates = this.additionalFields.filter((field) => {
        const value = this.state[field];
        return !this.autodata.find(item => item[field] === value);
      });

      if (updates.includes('maker')) {
        /**
         * let's see if we have a match on the
         */
        try {
          const result = await backend.matchMakerFromRoster(this.state.maker);
          if (result.confidence > 0) {
            if (result.maker !== this.state.maker) {
              this.setState({
                maker: result.maker
              });
              updates = updates.filter(update => update !== 'maker');
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      const updateAutodata = updates.length > 0;

      this.additionalFields.forEach((field) => {
        const value = this.state[field];
        image[field] = value;
        if (updateAutodata) {
          autodataUpdates[field] = value;
        }
      });
    }

    const validate = validator(this.props.schema);
    const valid = validate(image);
    if (!valid) {
      this.setState({
        errorMessage: getMostImportantErrorFromValidator(validate),
      });
      AppController.exitPotentiallyLengthyOperation();
      return;
    }

    const collectionErrors = validateAddingNewImage(AppController.store.getState(), image);
    if (collectionErrors) {
      this.setState({
        errorMessage: collectionErrors.pop().message,
      });
      AppController.exitPotentiallyLengthyOperation();
      return;
    }

    this.props.addNewImage(image);

    // we have no idea if the add was successful
    //  but we should be able to be assured the
    //  schema passed validation...
    AppController.updateAutodata(autodataUpdates);
    AppController.exitPotentiallyLengthyOperation();

    this.props.onClose();
  }

  resetAdditions() {
    if (this.additionalFields) {
      this.additionalFields.forEach(field => this.setState({
        [field]: '',
      }));
    }

  }

  async checkImageUpload() {

    const url = URL.createObjectURL(this.state.file);

    AppController.enterPotentiallyLengthyOperation('Thinking...');

    let exifData;

    try {
      exifData = await ExifReader.load(this.state.file);
    } catch (e) {
      console.log(`Fetching EXIF data failed ${e.toString()}`);
    }

    console.log(`Parsing filename ${this.state.file.name}`);
    const fetchMetadata = backend.fetchImageMetadataFromFilename(this.state.file.name, exifData);
    const preload = preloadImage(url);


    const checkImage = (image) => {
      let isValid = false;
      if (this.state.file.size > maxImageFileSize) {
        this.setState({
          validImage: false,
          errorMessage: `Image files cannot be larger than ${maxImageFileSize / 1000000}MB in size`,
          title: "",
        });
        this.resetAdditions();
      } else if (image.naturalWidth < 1920 && image.naturalHeight < 1080) {
        this.setState({
          validImage: false,
          errorMessage: "Image resolution is too low",
          title: "",
        });
        this.resetAdditions();
      } else if (image.naturalWidth > 4000 || image.naturalHeight > 4000) {
        this.setState({
          validImage: false,
          errorMessage: "Image resolution is too high",
          title: "",
        });
        this.resetAdditions();

      } else {
        isValid = true;
        this.setState({
          validImage: true,
          errorMessage: null,
        });
      }
      return isValid;
    };

    const checkCaptureDate = (exifTags) => {
      let isValid = true;
      if (this.campaign.votingStartDate) {
        if (!exifTags || !exifTags.DateCreated) {
          isValid = false;
          this.setState({
            validImage: false,
            errorMessage: `Images for this campaign must have EXIF information showing the image was taken between ${this.campaign.startDate} and ${this.campaign.endDate}`,
          });
          this.resetAdditions();
        } else if (!isDateWithinDateRange(new Date(exifTags.DateCreated.value), this.campaign.startDate, this.campaign.endDate)) {
          isValid = false;
          this.setState({
            validImage: false,
            errorMessage: `Images for this campaign must be taken between ${this.campaign.startDate} and ${this.campaign.endDate}`,
          });
          this.resetAdditions();
        }
      }
      return isValid;
    };

    const titleCase = (str) => {
      if (!str) {
        return str;
      }
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    };

    const fillPropsFromMetadata = (exifTags, metadata) => {
      console.log(`parser confidence ${metadata.confidence}`);
      if (this.additionalFields &&
        this.additionalFields &&
        this.campaign.exifFieldHelpers) {
        this.additionalFields.forEach((field) => {
          const helpers = this.campaign.exifFieldHelpers[field];
          let value;
          if (helpers) {
            value = helpers.reduce((value, tag) => {
              if (value) {
                return value;
              }
              return lodash.get(exifTags, `${tag}.description`, lodash.get(exifTags, `${tag}.value[0].description`));
            }, '');
          }
          value = metadata[field] || titleCase(value);
          this.setState({
            [field]: value || ''
          });
        });
        this.setState({
          title: titleCase(lodash.get(exifTags, 'title.value[0].description')) || (metadata.confidence > 0.3 ? metadata.title : metadata.alternate),
        });
        return true;
      } else {
        this.setState({
          title: titleCase(lodash.get(exifTags, 'title.value[0].description')) || (metadata.confidence > 0.3 ? metadata.title : metadata.alternate),
        });
        return true;
      }
    };

    Promise.all([preload, fetchMetadata])
      .then(([image, metadata]) => {
        return checkImage(image)
          && checkCaptureDate(exifData)
          && fillPropsFromMetadata(exifData, metadata);
      })
      .catch((error) => {
        this.setState({
          validImage: false,
          errorMessage: error.message,
        });
        this.resetAdditions();
      })
      .finally(() => {
        URL.revokeObjectURL(url);
        AppController.exitPotentiallyLengthyOperation();
      });
  }

  makeCategoryDisplayName(category) {
    const inventDisplayName = () => {
      return lodash.startCase(category);
    };
    return this.campaign.categoryDisplayNames ? (this.campaign.categoryDisplayNames[category] || inventDisplayName()) : inventDisplayName();
  }

  renderCategories() {
    return this.props.categories.map(category =>
      <MenuItem key={category} value={category}>{this.makeCategoryDisplayName(category)}</MenuItem>
    );
  }

  renderAdditions() {

    if (this.additionalFields) {
      const makeNamedSetter = (name) => {
        return (e, value) => {
          const newValue = (e && e.currentTarget.value) || value;
          const data = this.autodata.find(item => item[name] === newValue);

          this.additionalFields.forEach((field) => {
            this.setState({
              [field]: data ? data[field] : newValue,
            });
          });
        };
      };

      return this.additionalFields.map((field) => {
        const options = this.autodata.map(data => data[field]);

        return (<Autocomplete
          freeSolo
          margin="dense"
          id={field}
          name={field}
          key={field}

          fullWidth

          sx={{ marginTop: '10px' }}

          onOpen={() => { this.setState({ autocomplete: 'open' }); }}
          onClose={() => { this.setState({ autocomplete: 'closed' }); }}

          value={this.state[field]}
          options={options}
          onChange={makeNamedSetter(field)}
          renderInput={(params) =>
            <TextField
              {...params}
              required

              onChange={makeNamedSetter(field)}
              label={lodash.startCase(field)}
              onKeyDown={this.hotKeyHandler}
              variant="standard"
            />
          }
        />);
      });
    }
    return null;
  }

  renderMoreInfo() {
    return this.campaign.moreInfo && (<Link href={this.campaign.moreInfo} target="_blank">More info...</Link>);
  }

  render() {
    let OKButton;
    if (this.state.validImage) {
      OKButton = <Button variant="outlined" onClick={this.handleAddNewImage}>Continue</Button>;
    } else {
      OKButton = <Button variant="outlined" disabled>Continue</Button>;
    }

    const additionalFields = this.renderAdditions();

    const moreInfo = this.renderMoreInfo();
    const description = this.campaign.description;

    return (
      <div className={styles.container}>
        <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
          <DialogTitle><Logo variant="chip" className={styles.chip} />Add New Image</DialogTitle>
          {this.state.errorMessage && (
            <Alert severity="error" onClose={() => { this.handleCloseAlert(); }}>
              <AlertTitle>Error</AlertTitle>
              {this.state.errorMessage}
            </Alert>
          )}
          <DialogContent>
            <DialogContentText>
              {description}
              {moreInfo}
            </DialogContentText>
            <ImageDropzone
              onDrop={this.handleFileDropped}
              onKeyDown={this.handleDropzoneKey}
            />
            <Box className={styles.form}>
              <TextField
                autoFocus
                required
                margin="dense"
                key="title"
                id="title"
                name="title"
                label="Title"
                fullWidth
                variant="standard"
                value={this.state.title}
                onChange={this.handleTitleChanged}
                onKeyDown={this.hotKeyHandler}
              />
              <Box className={styles.categoryGrid}>
                <InputLabel
                  className={styles.categoryLabel}
                  id="category-select-label">
                  {this.categoryLabel}
                </InputLabel>
                <Select
                  className={styles.categorySelect}
                  labelId="category-select-label"
                  required
                  key="category"
                  variant="standard"
                  value={this.state.category}
                  onChange={this.handleCategoryChanged}
                  label="Age"
                  onKeyDown={this.hotKeyHandler}
                >
                  {this.renderCategories()}
                </Select>
              </Box>
            </Box>
            {additionalFields}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={this.handleCancel}>Cancel</Button>
            {OKButton}
          </DialogActions>
        </HotKeys>
      </div>
    );
  }
}

AddNewImageDialog.propTypes = {
  addNewImage: PropTypes.func.isRequired,
  campaign: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired
};
