import lodash from 'lodash';
import * as actions from '../actions';
import constants from '../../constants';
import { installCampaignValidator, installRosterValidator } from '../../Utils/SchemaUtils';
import { isTodayWithinDateRange } from '../../Utils/DateUtils';

const DEFAULT_STATE = {
  filter: {
    content: '',
    category: '*'
  },

  connectionStatus: {
    status: 'disconnected',
    serviceEndPoint: constants.API_ENDPOINT,
  },

  config: {
    campaigns: {
      [constants.DEFAULT_CAMPAIGN]: {
        id: constants.DEFAULT_CAMPAIGN,
        name: 'Competition to end all competitions',
        description: 'You cannot enter. All your image belong to us',
        rules: 'http://norules.com',
        schema: '/nope.json',
        maxEntriesPerUser: 0,
        minTitleLength: 1,
        startDate: 'December 1, 1970',
        endDate: 'December 31, 2999',
        categories: [
          'color',
          'monochrome'
        ]
      }
    },
    defaultCampaign: constants.DEFAULT_CAMPAIGN,
    currentCampaign: constants.DEFAULT_CAMPAIGN,
    makeups: {},
    messages: [],
    clientId: 'thunder-dev',
    loginURI: '/login',
    logoutURI: '/logout',
  },
  ready: false,
  user: '',
  makers: [],
  autodata: [],
};

function updateAutodata(state, autodata = []) {
  const newState = {
    ...state,
    autodata
  };
  installRosterValidator(autodata);
  return newState;
}

function updateReadyState(state, ready = true) {
  const newState = {
    ...state,
    ready,
  };
  return newState;
}

function updateUser(state, user) {
  const newState = {
    ...state,
    user,
  };
  return newState;
}

function initMakers(state, makers) {
  const newState = {
    ...state,
    makers,
  };
  return newState;
}

function popMessage(state) {
  const [, ...messages] = state.config.messages;
  const newState = {
    ...state,
    config: {
      ...state.config,
      messages
    }
  };
  return newState;
}

function updateCampaigns(state, config) {
  const newState = { ...state };
  if (Object.keys(config.campaigns).length > 0) {
    newState.config.currentCampaign = config.currentCampaign || constants.DEFAULT_CAMPAIGN;
    newState.config.defaultCampaign = config.currentCampaign || constants.DEFAULT_CAMPAIGN;

    newState.config.makeups = {
      ...config.makeups
    };

    if (config.message) {
      newState.config.messages.push(config.message);
    }

    newState.config.campaigns = {
      ...config.campaigns
    };

    let currentCampaign = config.campaigns[config.currentCampaign];
    if (!currentCampaign || !isTodayWithinDateRange(currentCampaign.startDate, currentCampaign.endDate)) {
      /*
       * campaign has expired, find the first one that is open and not ephemeral
       */
      currentCampaign = Object.values(config.campaigns).reduce((acc, campaign) => {
        if (!acc && isTodayWithinDateRange(campaign.startDate, campaign.endDate) && campaign.type !== 'ephemeral') {
          return campaign;
        }
        return acc;
      }, undefined);
    }

    if (!currentCampaign) {
      /*
       * no campaigns are open so just select the last one that is not ephemeral
       */
      currentCampaign = Object
        .values(config.campaigns)
        .filter(campaign => campaign.type !== 'ephemeral')
        .map(campaign => campaign.id)
        .pop();
    }

    newState.config.currentCampaign = currentCampaign.id;

    if (!newState.config.campaigns[newState.config.currentCampaign]) {
      const campaigns = Object.keys(newState.config.campaigns);
      if (campaigns.length === 0) {
        newState.config.campaigns = {
          ...newState.config.campaigns,
          ...DEFAULT_STATE.config.campaigns
        };
        newState.config.currentCampaign = constants.DEFAULT_CAMPAIGN;
        newState.config.defaultCampaign = constants.DEFAULT_CAMPAIGN;
      } else {
        newState.config.currentCampaign = campaigns[0];
        newState.config.defaultCampaign = campaigns[0];
      }
    }

    installCampaignValidator(Object.keys(config.campaigns));
  }
  return newState;
}


function updateServerConfiguration(state, config) {
  const newState = { ...state };

  newState.config.clientId = config.clientId;
  newState.config.loginURI = config.loginURI;
  newState.config.logoutURI = config.logoutURI;

  return newState;
}

function setActiveCampaign(state, campaign) {
  const newState = { ...state };
  newState.config.currentCampaign = campaign;
  return newState;
}


function updateConnectionStatus(state, status) {
  const newState = { ...state };
  newState.connectionStatus = {
    ...state.connectionStatus,
    ...status
  };
  return newState;
}

function resetFilters(state) {
  return {
    ...state,
    filter: {
      content: '',
      category: '*',
    },
  };
}

function applyFilter(state, content, category) {
  const newState = { ...state };
  newState.filter.content = content === undefined ? state.filter.content : content;
  newState.filter.category = category || state.filter.category;
  return newState;
}

const appState = (state = lodash.cloneDeep(DEFAULT_STATE), action) => {
  let newState;

  switch (action.type) {

    case actions.INIT_AUTODATA:
      newState = updateAutodata(state, action.payload.autodata);
      break;

    case actions.INIT_CAMPAIGNS:
      newState = updateCampaigns(state, action.payload.campaigns);
      break;

    case actions.FILTER_IMAGES:
      newState = applyFilter(state, action.payload.content, action.payload.category);
      break;

    case actions.SET_ACTIVE_CAMPAIGN:
      newState = setActiveCampaign(state, action.payload.campaign);
      break;

    case actions.RESET_FILTERS:
      newState = resetFilters(state);
      break;

    case actions.UPDATE_HEALTH:
      newState = updateConnectionStatus(state, action.payload);
      break;

    case actions.SERVER_CONFIG:
      newState = updateServerConfiguration(state, action.payload.config);
      break;

    case actions.UPDATE_READY_STATE:
      newState = updateReadyState(state, lodash.get(action, 'payload.ready', true));
      break;

    case actions.POP_MESSAGE:
      newState = popMessage(state);
      break;

    case actions.UPDATE_USER:
      newState = updateUser(state, action.payload.user);
      break;

    case actions.INIT_MAKERS:
      newState = initMakers(state, action.payload.makers);
      break;

    default:
      return state;
  }


  return newState;
};

export default appState;
