import lodash from 'lodash';
import Immutable from 'immutable';
import * as actions from '../actions';

const BASE_STATE = {};

const DEFAULT_STATE = Immutable.fromJS(BASE_STATE);

function resetImageList(state) {
  return DEFAULT_STATE;
}

function initializeImageList(state, images) {
  const data = images.reduce((acc, image) => {
    acc[image.id] = image;
    return acc;
  }, lodash.clone(BASE_STATE));
  return Immutable.fromJS(data);
}

export function hasImageById(state, id) {
  const result = state.get(id);
  return !!result;
}

export function filterImagesByTitleAndCampaign(state, title, campaign) {
  const tl = title.toLowerCase().trim();
  return Object.values(state.toJS()).filter((image) => {
    return image.campaign === campaign &&
      image.title.toLowerCase().trim() === tl;
  });
}

export function getImageById(state, id) {
  const result = state.get(id);
  return result ? result.toJS() : null;
}

function addImage(state, image) {
  const id = image.id;
  if (!id) {
    throw new Error(`Malformed Image ${image}`);
  }

  if (hasImageById(state, id)) {
    throw new Error(`Duplicate image ID ${id}`);
  }

  const updated = state.update(id, () => {
    return Immutable.fromJS(image);
  });

  return updated;
}

function removeImage(state, id) {

  if (!hasImageById(state, id)) {
    throw new Error(`IMAGE ${id} not found.`);
  }

  return state.remove(id);
}

function updateImage(state, image) {
  const id = image.id;
  return state.set(id, Immutable.fromJS(image));
}


export default function images(state = DEFAULT_STATE, action) {
  switch (action.type) {

    case actions.INIT_IMAGE_LIST:
      return initializeImageList(state, action.payload.images);

    case actions.RESET_IMAGE_LIST:
      return resetImageList(state);

    case actions.ADD_IMAGE:
      return addImage(state, action.payload.image);

    case actions.REMOVE_IMAGE:
      return removeImage(state, action.payload.id);

    case actions.REPLACE_IMAGE:
      return updateImage(state, action.payload.image);

    default:
      break;
  }

  return state;
}
