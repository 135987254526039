import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { connect } from 'react-redux';

import AppController from '../../Controllers/AppController';

import LengthyOperationDialog from '../../Dialogs/LengthyOperationDialog';
import ImageCatalogView from '../ImageCatalogView';
import MessageTray from '../MessageTray';
import SleeveView from '../SleevesView';

import styles from './Workspace.module.scss';

class Workspace extends Component {
  constructor(props) {
    super(props);
    this.history = createHistory();
    AppController.history = this.history;
  }

  render() {
    return (
        <div className={styles.workspace}>
            <Router history={this.history}>
              <Switch>
                  <Route exact path="/">
                    <Redirect to="/catalog" replace={true} />
                  </Route>
                  <Route exact path="/catalog" component={ImageCatalogView} />
                  <Route exact path="/sleeves" component={SleeveView} />
                  <Route>
                    <Redirect to="/" replace={true} />
                </Route>
              </Switch>
            </Router>
          <MessageTray />
          <LengthyOperationDialog />
      </div>
    );
  }
}


Workspace.propTypes = {
};

Workspace.defaultProps = {
};

Workspace.connector = (state) => {
  return {
  };
};

export default connect(
  Workspace.connector
)(Workspace);
