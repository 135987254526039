export default class HttpError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = 'HTTPError';
    this.statusCode = statusCode;
  }
}

export class UnexpectedResponseError extends Error {
  constructor(response) {
    super(response.statusText);
    this.name = 'UnexpectedResponse';
    this.statusCode = response.status;
    response.text()
      .then(text => {
        console.log(`UnexpectedResponse: ${text}`);
      })
      .catch(error => {
        console.log(
          `An unexpected response was returned from the server that was
           not JSON and not TEXT. The error was ${error.message}}
           The response type was ${response.headers.get("content-type")}}`);
      });
  }
}
