import URI from 'urijs';
import EventEmitter from 'eventemitter3';

import constants, { MAKE_API } from "../constants";
import HttpError, { UnexpectedResponseError } from "../Utils/HttpError";
import { responseHasJSON } from '../Utils/fetchUtils';

class Backend extends EventEmitter {
  constructor() {
    super();

    this.imagesEndpoint = MAKE_API('images');
    this.imageEndpoint = MAKE_API('image');
    this.metadataEndpoint = MAKE_API('metadata');
    this.autodataEndpoint = MAKE_API('autodata');
    this.makersEndpoint = MAKE_API('makers');
    this.sleeveEndpoint = MAKE_API('sleeve');
    this.sleevesEndpoint = MAKE_API('sleeves');

    this.healthEndpoint = MAKE_API('health');
    this.configEndpoint = MAKE_API('config');
    this.schemasEndpoint = MAKE_API('schemas');
    this.campaignsEndpoint = MAKE_API('campaigns');
    this.userEndPoint = MAKE_API('user');

    this.fetchAutodata = this.fetchAutodata.bind(this);
    this.fetchMakers =this.fetchMakers.bind(this);
    this.updateAutodata = this.updateAutodata.bind(this);
    this.fetchImages = this.fetchImages.bind(this);
    this.addImage = this.addImage.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.fetchSleeves = this.fetchSleeves.bind(this);
    this.addSleeve = this.addSleeve.bind(this);
    this.updateSleeve = this.updateSleeve.bind(this);
    this.fetchHealth = this.fetchHealth.bind(this);
    this.fetchConfiguration = this.fetchConfiguration.bind(this);
    this.fetchCampaigns = this.fetchCampaigns.bind(this);
    this.fetchSchemas = this.fetchSchemas.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.fetchImageMetadataFromFilename = this.fetchImageMetadataFromFilename.bind(this);
    this.matchMakerFromRoster = this.matchMakerFromRoster.bind(this);

    this.commonHeaders = {
    };
  }

  setAccessToken(token) {
    this.commonHeaders.Authorization = token;
  }

  async fetchImages() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.fetchingImages);
      const response = await fetch(this.imagesEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }


  async addImage({
    campaign = constants.DEFAULT_CAMPAIGN,
    title,
    category,
    file,
    ...additionalFields
  }) {
    const body = new FormData();
    if (campaign) {
      body.append('campaign', campaign);
    }
    if (title) {
      body.append('title', title);
    }
    if (category) {
      body.append('category', category);
    }
    if (file) {
      body.append('image', file);
    }

    if (additionalFields) {
      Object.entries(additionalFields).forEach(([field, value]) => body.append(field, value));
    }

    const options = {
      method: 'POST',
      body,

      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.addingImage);
      const response = await fetch(this.imageEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async updateImage({
    id,
    title,
    category,
    campaign,
  }) {
    const body = new FormData();
    if (campaign) {
      body.append('campaign', campaign);
    }
    if (title) {
      body.append('title', title);
    }
    if (category) {
      body.append('category', category);
    }

    const options = {
      method: 'PATCH',
      body,

      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.updatingImage);
      const response = await fetch(URI.joinPaths(this.imageEndpoint, id), options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async removeImage(id) {
    const options = {
      method: 'DELETE',
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.deletingImage);

      const response = await fetch(MAKE_API('image', id), options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchHealth() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const response = await fetch(this.healthEndpoint, options);
    if (!response.ok) {
      if (responseHasJSON(response)) {
        const error = await response.json();
        if (error.message) {
          throw new HttpError(response.status, error.message);
        }
      }
      throw new HttpError(response.status, response.statusText);
    }
    if (!responseHasJSON(response)) {
      throw new UnexpectedResponseError(response);
    }
    const health = await response.json();
    return health;
  }

  async fetchSleeves() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.fetchingSleeves);
      const response = await fetch(this.sleevesEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }


  async addSleeve(title) {
    const body = JSON.stringify({ title });

    const options = {
      method: 'POST',
      body,

      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json',
      },
    };
    try {
      this.emit(this.statusEvent, this.status.addingSleeve);
      const response = await fetch(this.sleeveEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async updateSleeve(sleeve) {
    const body = JSON.stringify(sleeve);
    const options = {
      method: 'PATCH',
      body,

      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json',
      },
    };
    try {
      this.emit(this.statusEvent, this.status.updatingSleeve);
      const response = await fetch(URI.joinPaths(this.sleeveEndpoint, sleeve.id), options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchUserInfo() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const response = await fetch(this.userEndPoint, options);
    if (!response.ok) {
      if (responseHasJSON(response)) {
        const error = await response.json();
        if (error.message) {
          throw new HttpError(response.status, error.message);
        }
      }
      throw new HttpError(response.status, response.statusText);
    }
    if (!responseHasJSON(response)) {
      throw new UnexpectedResponseError(response);
    }
    const userInfo = await response.json();
    return userInfo;
  }

  async matchMakerFromRoster(maker) {
    const body = JSON.stringify({
      maker
    });
    const options = {
      method: 'POST',
      body,

      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(this.metadataEndpoint, options);
    if (!response.ok) {
      if (responseHasJSON(response)) {
        const error = await response.json();
        if (error.message) {
          throw new HttpError(response.status, error.message);
        }
      }
      throw new HttpError(response.status, response.statusText);
    }
    if (!responseHasJSON(response)) {
      throw new UnexpectedResponseError(response);
    }
    const metadata = await response.json();
    return metadata;
  }

  async fetchImageMetadataFromFilename(filename, exifData = {}) {
    const body = JSON.stringify({
      filename,
      exifData,
    });
    const options = {
      method: 'POST',
      body,

      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(this.metadataEndpoint, options);
    if (!response.ok) {
      if (responseHasJSON(response)) {
        const error = await response.json();
        if (error.message) {
          throw new HttpError(response.status, error.message);
        }
      }
      throw new HttpError(response.status, response.statusText);
    }
    if (!responseHasJSON(response)) {
      throw new UnexpectedResponseError(response);
    }
    const metadata = await response.json();
    return metadata;
  }

  async updateAutodata(data) {
    const body = JSON.stringify(data);
    const options = {
      method: 'POST',
      body,

      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json',
      },
    };
    try {
      this.emit(this.statusEvent, this.status.updatingConfig);
      const response = await fetch(this.autodataEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchMakers() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.fetchingConfig);
      const response = await fetch(this.makersEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchAutodata() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.fetchingConfig);
      const response = await fetch(this.autodataEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchConfiguration() {
    try {
      this.emit(this.statusEvent, this.status.fetchingConfig);
      const response = await fetch(this.configEndpoint);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }


  async fetchCampaigns() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.fetchingCampaigns);
      const response = await fetch(this.campaignsEndpoint, options);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchSchemas() {
    try {
      this.emit(this.statusEvent, this.status.fetchingSchemas);
      const response = await fetch(this.schemasEndpoint);
      if (!response.ok) {
        if (responseHasJSON(response)) {
          const error = await response.json();
          if (error.message) {
            throw new HttpError(response.status, error.message);
          }
        }
        throw new HttpError(response.status, response.statusText);
      }
      if (!responseHasJSON(response)) {
        throw new UnexpectedResponseError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }
}

Backend.prototype.statusEvent = 'status';

Backend.prototype.status = {
  idle: 'idle',
  updatingImage: 'Updating Image',
  fetchingImages: 'Fetching Images',
  addingImage: 'Adding Image',
  deletingImage: 'Deleting Image',
  updatingSleeve: 'Updating Sleeve',
  fetchingSleeves: 'Fetching Sleeves',
  addingSleeve: 'Adding Sleeve',
  fetchingCampaigns: 'Fetching Campaigns',
  updatingConfig: 'Updating Config',
  fetchingConfig: 'Fetching Config',
};

const backend = new Backend();
export default backend;
