import { combineReducers } from 'redux';

import appState from './app-state';
import images from './images';
import schemas from './schemas';
import sleeves from './sleeves';

export default combineReducers({
  appState,
  images,
  schemas,
  sleeves
});
