import { parseDate } from 'chrono-node';

export function isDateWithinDateRange(date, startText, endText) {
  let start = new Date();
  let end = new Date();

  if (startText) {
    start = new Date(parseDate(`${startText} 0:00`));
  }
  if (endText) {
    end = new Date(parseDate(`${endText} 23:59`));
  }

  return date >= start && date <= end;
}

export function isTodayWithinDateRange(startText, endText) {
  return isDateWithinDateRange(new Date(), startText, endText);
}

export function isBeforeDate(dateText) {
  if (!dateText) {
    return false;
  }
  const date = new Date(parseDate(`${dateText} 0:00`));
  const currentDate = new Date();
  return currentDate < date;
}

export function isAfterDate(dateText) {
  if (!dateText) {
    return false;
  }
  const date = new Date(parseDate(`${dateText} 23:59`));
  const currentDate = new Date();
  return currentDate > date;
}

export function getMonthName(dateText) {
  const date = dateText ? new Date(parseDate(`${dateText} 23:59`)) : new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  return month;
}

export function isNearingDate(dateText, near = '3 days') {
  if (!dateText) {
    return false;
  }
  if (isAfterDate(dateText)) {
    return false;
  }

  const terminator = `${near} before`;
  const parsed = parseDate(terminator, {
    instant: new Date(parseDate(dateText)),
  });
  const date = new Date(parsed);
  const currentDate = new Date();
  return date <= currentDate;
}
