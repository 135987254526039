
export const FILTER_IMAGES = 'thunder/application/filters/content';
export const RESET_FILTERS = 'thunder/application/filters/reset';

export const SET_ACTIVE_CAMPAIGN = 'thunder/application/active-campaign/set';

export const UPDATE_HEALTH = 'thunder/application/health/update';
export const SERVER_CONFIG = 'thunder/application/config';
export const UPDATE_READY_STATE = 'thunder/application/ready-state/update';
export const SYNCHRONIZE_DATA_MODEL_WITH_REQUEST = 'thunder/application/synchronize';

export const UPDATE_USER = 'thunder/application/user';

export const RESET_IMAGE_LIST = 'thunder/images/reset';
export const INIT_IMAGE_LIST = 'thunder/images/initialize';
export const ADD_IMAGE = 'thunder/images/add';
export const REPLACE_IMAGE = 'thunder/images/replace';
export const REMOVE_IMAGE = 'thunder/images/remove';

export const INIT_AUTODATA = 'thunder/autodata/initialize';
export const INIT_MAKERS = 'thunder/makers/initialize';
export const UPDATE_AUTODATA = 'thunder-server/autodata/update';
export const POP_MESSAGE = `thunder/message/pop`;

export const INIT_CAMPAIGNS = 'thunder/campaigns/initialize';

export const FETCH_IMAGE_LIST = 'thunder-server/images/fetch';
export const ADD_NEW_IMAGE = 'thunder-server/images/add';
export const DELETE_IMAGE = 'thunder-server/images/delete';
export const UPDATE_IMAGE = 'thunder-server/images/update';

export const INIT_SCHEMA_LIST = 'thunder/schemas/initialize';
export const FETCH_SCHEMA_LIST = 'thunder-server/schemas/initialize';

export const INIT_SERVER_HEALTH = 'thunder-server/health/initialize';
export const FETCH_HEALTH = 'thunder-server/health/fetch';

export const NAVIGATE = 'thunder/navigate';
export const INITIALIZE = 'thunder/initialize';
export const LOGOUT = 'thunder/logout';

export const INIT_SLEEVES = 'thunder/sleeves/initialize';
export const ADD_SLEEVE = 'thunder/sleeves/add';
export const REPLACE_SLEEVE = 'thunder/sleeves/update';

export const ADD_NEW_SLEEVE = 'thunder-server/sleeve/add';
export const UPDATE_SLEEVE = 'thunder-server/sleeve/update';
export const ADD_BLANKS = 'thunder-server/sleeve/add-blanks';
