import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';

import styles from './Dropzone.module.scss';

export default class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    if (files.length < 1) {
      console.log('Received a drop without any files');
      return;
    }
    this.props.onDrop(files);
  }

  renderDropHereContent() {
    return (
      <div className={styles.uploader}>
        <CloudUploadIcon sx={{ fontSize: '300px' }} />
        <Typography variant="body2" color="text.secondary">
          Drop your image here or click to browse
        </Typography>
      </div>
    );
  }

  renderContent() {
    return this.renderDropHereContent();
  }

  render() {
    return (
      <ReactDropzone onDrop={this.onDrop} accept={this.accept} maxFiles={1} disabled={this.props.readonly}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className={styles.dropZone}>
              {this.renderContent()}
            </div>
          </div>
        )}
      </ReactDropzone>
    );
  }
}

Dropzone.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  readonly: PropTypes.bool,
};

Dropzone.defaultProps = {
  onDrop: () => { },
  accept: null,
  readonly: false,
};
