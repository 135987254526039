import React, { Component } from 'react';

import RingLoader from 'react-spinners/RingLoader';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './LengthyOperationDialog.module.scss';

import AppController from '../../Controllers/AppController';

const classes = {
  "& .MuiDialog-paper": {
    height: '240px',
    width: '240px',
  }
};
export default class LengthyOperationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: null,
    };
    this.timerId = null;
  }
  componentDidMount() {
    AppController.on(AppController.lengthyOperationStartEvent, (title) => {
      if (!this.timerId) {
        // wait some time then show...
        this.timerId = setTimeout(() => {
          this.setState({
            title,
            timerId: null,
            open: true
          });
        }, 100);
      }
    });
    AppController.on(AppController.lengthyOperationEndEvent, () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.setState({
        open: false,
        title: null,
      });
    });
  }

  render() {
    return (
      <Dialog sx={classes} open={this.state.open}>
        <DialogTitle>{this.state.title || 'Working on it...'}</DialogTitle>
        <DialogContent>
          <div className={styles.loader}>
            <RingLoader className={styles.loader} color="black" />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
