import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { GlobalHotKeys } from 'react-hotkeys';

import CloseIcon from '@mui/icons-material/Close';

import * as actions from '../../model/actions';

import styles from './MessageTray.module.scss';

class MessageTray extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);

    this.keyMap = {
      Cancel: 'Escape'
    };
    this.handlers = {
      Cancel: this.handleClose
    };
  }

  handleClose() {
    this.props.popMessage();
  }

  render() {
    const [message] = this.props.messages;
    const { ready } = this.props;
    const show = message && ready;
    const classes = clsx(styles.container, { [styles.show]: show });
    const backdropClasses = clsx(styles.backdrop, { [styles.show]: show });
    return (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <Box className={backdropClasses}>
          <Box className={classes}>
            {show && (
              <Box className={styles.tray}>
                <Box className={styles.closeButton} onClick={this.handleClose} title="close"><CloseIcon className={styles.closeIcon} /></Box>
                <iframe
                  srcDoc={message.content}
                  title={message.title}
                  className={styles.message}
                  referrerPolicy="origin"
                >
                </iframe>
              </Box>
            )}
          </Box>
        </Box>
      </GlobalHotKeys>
    );
  }
}

MessageTray.propTypes = {
  messages: PropTypes.array,
  ready: PropTypes.bool,
};

MessageTray.defaultProps = {
  messages: [],
  ready: false,
};

MessageTray.connector = (state) => {
  const {
    messages,
  } = state.appState.config;
  const {
    ready,
  } = state.appState;

  return {
    messages,
    ready,
  };
};

MessageTray.commander = (dispatch) => {
  return {
    popMessage: () => dispatch({ type: actions.POP_MESSAGE }),
  };
};

export default connect(
  MessageTray.connector,
  MessageTray.commander
)(MessageTray);
